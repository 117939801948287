// LoginPage.tsx
import React from "react";
import { useAuth0 } from "@auth0/auth0-react";

const LoginPage: React.FC = () => {
  const { loginWithRedirect } = useAuth0();

  const handleLogin = () => {
    loginWithRedirect();
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
        fontFamily: "'Montserrat', sans-serif",
      }}
    >
      <img
        src="/images/museo-logo.webp"
        alt="Museo AI Logo"
        style={{ width: "200px", marginBottom: "20px", color: "white" }}
      />
      <h1 style={{ color: "white" }}>Welcome to Museo AI</h1>
      <p style={{ color: "white" }}>Please log in to continue.</p>
      <button
        onClick={handleLogin}
        className="button" // Use className for consistent button styling
      >
        Log In
      </button>
    </div>
  );
};

export default LoginPage;

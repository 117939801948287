import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Container,
  CardContent,
  Card,
  Grid,
  Typography,
  IconButton,
  Button,
} from "@mui/material";
import "../../../index.css";
import AutoFixHighIcon from "@mui/icons-material/AutoFixHigh";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { ApiService } from "../../../services/ApiService";
import { useAuth0 } from "@auth0/auth0-react";
import { SubArticleModel } from "../../../models/SubArticleModel";
import { ClusterArticleModel } from "../../../models/ClusterArticleModel";
import { CircularProgress } from "@mui/material";
import { toast } from "react-toastify";
import { useEditor, EditorContent } from "@tiptap/react";
import { extensions } from "./BlogEditor";
import { TextSelection } from "prosemirror-state";
import CodeMirror from "@uiw/react-codemirror";
import { html } from "@codemirror/lang-html";
import commonStyles from "../../../styles/commonStyles";
import { formatHtml } from "../../../utils/parsingHtml";
import BlogEditorAppBar from "./BlogEditorAppBar";
import { handleDownload } from "./utils/handleDownload";
import SubblogAccordion from "./SubblogAccordion";
import useBlogDetails from "../../../hooks/useBlogDetails";
import useAutoSave from "../../../hooks/useAutoSave";
import { TextField } from "@mui/material";

import EmailIcon from "@mui/icons-material/Email";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import LinkIcon from "@mui/icons-material/Link";
import { parse } from "node-html-parser";
import { ChatbotDialog } from "./ChatBot";

const handleCopyLink = () => {
  navigator.clipboard
    .writeText(window.location.href)
    .then(() => toast.success("Link copied to clipboard!"))
    .catch((err) => toast.error("Failed to copy link: " + err));
};

const handleShareOnFacebook = () => {
  const url = encodeURIComponent(window.location.href);
  const shareUrl = `https://www.facebook.com/sharer/sharer.php?u=${url}`;
  window.open(shareUrl, "_blank", "noopener,noreferrer");
};

const handleShareViaEmail = () => {
  const subject = encodeURIComponent("Check out this article!");
  const body = encodeURIComponent(window.location.href);
  const mailtoUrl = `mailto:?subject=${subject}&body=${body}`;

  navigator.clipboard
    .writeText(mailtoUrl)
    .then(() => {
      alert(
        "Article URL copied to clipboard. Please paste it into your email client."
      );
    })
    .catch((err) => {
      console.error("Failed to copy URL to clipboard:", err);
      alert(
        "Failed to copy the article URL. Please manually copy and paste the URL into your email client."
      );
    });
};

const handleShareOnTwitter = () => {
  const url = encodeURIComponent(window.location.href);
  const text = encodeURIComponent("Check out this amazing article!");
  // Add hashtags as needed, for example: encodeURIComponent("Tech, Blogging")
  const hashtags = encodeURIComponent("YourHashtagHere");
  const via = "YourTwitterHandle"; // Optional: your or the site's Twitter handle without '@'

  const twitterUrl = `https://twitter.com/intent/tweet?url=${url}&text=${text}&hashtags=${hashtags}&via=${via}`;
  window.open(twitterUrl, "_blank", "noopener,noreferrer");
};

const BlogDetailPage: React.FC = () => {
  const navigate = useNavigate();
  const { blogId } = useParams<{ blogId: string }>();
  const { getAccessTokenSilently } = useAuth0();
  const [selectedArticle, setSelectedArticle] = useState<
    SubArticleModel | ClusterArticleModel | null
  >(null);
  const [articleTitles, setArticleTitles] = useState<{ [id: number]: string }>(
    {}
  );

  const [autoSaveStatus, setAutoSaveStatus] = useState<
    "idle" | "saving" | "saved"
  >("idle");
  const [autoSaveMessage, setAutoSaveMessage] = useState("");
  const { blogDetails, isLoading, refetch } = useBlogDetails(
    blogId!,
    getAccessTokenSilently
  );
  const [blogTopic, setBlogTopic] = useState(blogDetails?.topic || "");
  const [subblogTitles, setSubblogTitles] = useState<{ [id: number]: string }>(
    {}
  );
  const [selectedColor, setSelectedColor] = useState("#000000"); // Default color
  const [viewMode, setViewMode] = useState<"raw" | "rendered">("rendered");
  const [formattedContent, setFormattedContent] = useState("");
  const [, setSelectedArticleTitle] = useState("");
  const [openSubblogId, setOpenSubblogId] = useState<number | null>(null);
  const [isGeneratingImage, setIsGeneratingImage] = useState(false);
  const [, setRawContent] = useState("");
  const [generationStatus, setGenerationStatus] = useState<
    "idle" | "thinking" | "done"
  >("idle");

  const [, setGenerationMessage] = useState("");
  const [comments, setComments] = useState<string[]>([]);
  const [newComment, setNewComment] = useState("");

  const handleCommentSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (newComment.trim() !== "") {
      setComments([...comments, newComment]);
      setNewComment("");
    }
  };

  useEffect(() => {
    if (selectedArticle) {
      setRawContent(selectedArticle.content || "");
    }
  }, [selectedArticle]);

  const handleNewImage = async (title: string) => {
    try {
      setIsGeneratingImage(true);
      const newImageUrl = await ApiService.regenerateArticleImage(
        title,
        getAccessTokenSilently
      );

      // Parse the generated content (HTML)
      const parser = new DOMParser();
      const doc = parser.parseFromString(selectedArticle!.content, "text/html");

      // Find the <img> tag and replace the src attribute with the new image URL
      const imgElement = doc.querySelector("img");
      if (imgElement) {
        imgElement.src = newImageUrl;

        // Update the selected article's content with the modified HTML
        const updatedContent = doc.documentElement.outerHTML;
        setSelectedArticle((currentArticle) => {
          if (currentArticle) {
            const updatedArticle = {
              ...currentArticle,
              content: updatedContent,
            };
            autoSaveArticle(updatedArticle, updatedContent);
            return updatedArticle;
          }
          return currentArticle;
        });
      }

      toast.success("New image generated successfully");
      setIsGeneratingImage(false);
    } catch (error) {
      console.error("Error generating new image:", error);
      toast.error("Failed to generate new image");
      setIsGeneratingImage(false);
    }
  };
  const autoSaveSubArticle = useCallback(
    async (articleId: number, articleData: Partial<SubArticleModel>) => {
      const blogId = blogDetails?.id || "";
      try {
        setAutoSaveStatus("saving");
        const response = await ApiService.autoSaveSubArticle(
          blogId!,
          articleId,
          articleData,
          getAccessTokenSilently
        );
        setAutoSaveStatus("saved");
        setAutoSaveMessage(response.message);
        setTimeout(() => {
          setAutoSaveStatus("idle");
          setAutoSaveMessage("");
        }, 2000);
      } catch (error) {
        console.error("Error auto-saving sub-article:", error);
        setAutoSaveStatus("idle");
      }
    },
    [blogDetails, getAccessTokenSilently]
  );

  const autoSaveClusterArticle = useCallback(
    async (articleId: number, articleData: Partial<ClusterArticleModel>) => {
      try {
        setAutoSaveStatus("saving");
        const response = await ApiService.autoSaveClusterArticle(
          blogId!,
          articleId,
          articleData,
          getAccessTokenSilently
        );

        setAutoSaveStatus("saved");
        setAutoSaveMessage(response.message);
        setTimeout(() => {
          setAutoSaveStatus("idle");
          setAutoSaveMessage("");
        }, 2000);
      } catch (error) {
        console.error("Error auto-saving cluster article:", error);
        setAutoSaveStatus("idle");
      }
    },
    [blogId, getAccessTokenSilently]
  );

  // Example of setting the editor's content with the title included.
  const autoSaveArticle = useCallback(
    async (article: SubArticleModel | ClusterArticleModel, content: string) => {
      console.log("article", article);
      if ("clusterId" in article) {
        await autoSaveSubArticle(article.id, {
          ...article,
          content: content,
        });
      } else if ("articles" in article) {
        await autoSaveClusterArticle((article as ClusterArticleModel).id, {
          ...(article as ClusterArticleModel),
          content: content,
        });
      }

      // Update the articleTitles state
      setArticleTitles((prevTitles) => ({
        ...prevTitles,
        [article.id]: article.title,
      }));
    },
    [autoSaveSubArticle, autoSaveClusterArticle]
  );
  const editor = useEditor({
    extensions: extensions,
    content: "",
    parseOptions: {
      preserveWhitespace: "full",
    },
    onUpdate: ({ editor }) => {
      const content = editor.getHTML();

      // Parse the title from the content
      const parser = new DOMParser();
      const doc = parser.parseFromString(content, "text/html");
      const title = doc.querySelector("h1")?.textContent || "";

      // Update the title in the state
      if (selectedArticle) {
        const isSubArticle = "clusterId" in selectedArticle;
        const updateTitleMethod = isSubArticle
          ? handleSubArticleTitleChange
          : handleClusterArticleTitleChange;
        updateTitleMethod(selectedArticle.id, title);

        // Update the selected article content
        setSelectedArticle((currentArticle) => {
          if (currentArticle) {
            const updatedArticle = {
              ...currentArticle,
              title: title,
              content: content,
            };
            autoSaveArticle(updatedArticle, content);
            return updatedArticle;
          }
          return currentArticle;
        });
      }
    },
    editorProps: {
      attributes: {
        class: "ProseMirror",
      },
    },
  });

  const updateArticleContent = useCallback(
    (newContent: string) => {
      setSelectedArticle((currentArticle) => {
        if (currentArticle) {
          const parser = new DOMParser();
          const doc = parser.parseFromString(newContent, "text/html");
          const newTitle =
            doc.querySelector("h1")?.textContent || currentArticle.title;

          const updatedArticle = {
            ...currentArticle,
            title: newTitle,
            content: newContent,
          };

          // Update the articleTitles state
          setArticleTitles((prevTitles) => ({
            ...prevTitles,
            [currentArticle.id]: newTitle,
          }));

          // Trigger auto-save with the new title and content
          autoSaveArticle(updatedArticle, newContent);

          return updatedArticle;
        }
        return currentArticle;
      });

      setRawContent(newContent);

      if (viewMode === "raw") {
        setFormattedContent(formatHtml(newContent));
      } else if (editor) {
        editor.commands.setContent(newContent);
      }
    },
    [viewMode, editor, autoSaveArticle]
  );

  useEffect(() => {
    if (selectedArticle) {
      if (viewMode === "raw") {
        setFormattedContent(formatHtml(selectedArticle.content || ""));
      } else {
        // Update the editor content when switching to rendered view
        if (editor) {
          editor.commands.setContent(selectedArticle.content || "");
        }
      }
    }
  }, [selectedArticle, viewMode, editor]);

  // Use the useAutoSave hook
  useAutoSave(selectedArticle?.content || "", async (content: string) => {
    if (selectedArticle) {
      await autoSaveArticle(selectedArticle, content);
    }
  });

  const handleArticleSelect = (
    article: SubArticleModel | ClusterArticleModel
  ) => {
    setSelectedArticle(article);
  };
  const updateArticleTitle = useCallback(
    (articleId: number, newTitle: string) => {
      setArticleTitles((prevTitles) => ({
        ...prevTitles,
        [articleId]: newTitle,
      }));
    },
    []
  );

  const handleSubArticleTitleChange = useCallback(
    (articleId: number, title: string) => {
      updateArticleTitle(articleId, title);
      setSelectedArticle((currentArticle) => {
        if (
          currentArticle &&
          "clusterId" in currentArticle &&
          currentArticle.id === articleId
        ) {
          return {
            ...currentArticle,
            title: title,
          };
        }
        return currentArticle;
      });
    },
    [updateArticleTitle]
  );

  const handleClusterArticleTitleChange = useCallback(
    (articleId: number, title: string) => {
      updateArticleTitle(articleId, title);
      setSelectedArticle((currentArticle) => {
        if (
          currentArticle &&
          "articles" in currentArticle &&
          currentArticle.id === articleId
        ) {
          return {
            ...currentArticle,
            title: title,
          };
        }
        return currentArticle;
      });
    },
    [updateArticleTitle]
  );

  useEffect(() => {
    if (selectedArticle && editor) {
      // Save cursor position
      const { from, to } = editor.state.selection;

      // Update editor content
      const newContent = selectedArticle.content || "";
      if (editor.getHTML() !== newContent) {
        editor.commands.setContent(newContent, false);

        // Restore cursor position if possible
        const newFrom = Math.min(from, editor.state.doc.content.size);
        const newTo = Math.min(to, editor.state.doc.content.size);
        editor.view.dispatch(
          editor.state.tr.setSelection(
            TextSelection.create(editor.state.doc, newFrom, newTo)
          )
        );
      }
    }
  }, [selectedArticle, editor]);

  useEffect(() => {
    if (blogDetails) {
      setBlogTopic(blogDetails.topic);
    }
  }, [blogDetails]);

  const handleAddLink = () => {
    const url = prompt("Enter the URL");
    if (url) {
      editor
        ?.chain()
        .focus()
        .extendMarkRange("link")
        .setLink({ href: url })
        .run();
    }
  };

  // Inside your component, add a state for color selection

  // Update the handleFontChange and handleFontSizeChange to correctly apply styles
  const handleFontChange = (fontFamily: string) => {
    editor?.chain().focus().setFontFamily(fontFamily).run();
  };

  const handleFontSizeChange = (fontSize: string) => {
    editor?.chain().focus().setFontSize(fontSize).run();
  };
  // Implement handleColorChange method
  const handleColorChange = (color: string) => {
    editor?.chain().focus().setColor(color).run();
  };

  const handleBack = () => navigate(-1);

  const deleteClusterArticle = async (clusterId: number) => {
    try {
      await ApiService.deleteClusterArticle(
        blogId!,
        clusterId,
        getAccessTokenSilently
      );
      toast.success("Cluster article deleted successfully");
      refetch(); // Refetch to update the UI
    } catch (error) {
      console.error("Error deleting cluster article:", error);
      toast.error("Failed to delete the cluster article");
    }
  };
  const deleteSubblog = async (subblogId: number) => {
    try {
      await ApiService.deleteSubblog(
        blogId!,
        subblogId,
        getAccessTokenSilently
      );
      toast.success("Subblog deleted successfully");
      refetch(); // Refetch to update the UI
    } catch (error) {
      console.error("Error deleting subblog:", error);
      toast.error("Failed to delete the subblog");
    }
  };

  const deleteSubArticle = async (articleId: number) => {
    try {
      await ApiService.deleteSubArticle(
        blogId!,
        articleId,
        getAccessTokenSilently
      );
      toast.success("Sub article deleted successfully");
      refetch(); // Refetch to update the UI
    } catch (error) {
      console.error("Error deleting sub article:", error);
      toast.error("Failed to delete the sub article");
    }
  };

  const handleArticleClick = async (article: SubArticleModel) => {
    console.log("Clicked article:", article);
    try {
      const updatedArticle = await ApiService.fetchArticle(
        blogId!,
        article.id,
        "sub-articles",
        getAccessTokenSilently
      );

      // Parse the HTML content
      const root = parse(updatedArticle.content || "");
      const existingH1 = root.querySelector("h1");

      let contentWithTitle: string;
      if (
        existingH1 &&
        existingH1.text.trim() === updatedArticle.title.trim()
      ) {
        // If there's already an H1 with the correct title, use the content as is
        contentWithTitle = updatedArticle.content || "";
      } else {
        // If there's no H1 or it doesn't match the title, add one
        contentWithTitle = `<h1>${updatedArticle.title}</h1>${updatedArticle.content || ""}`;
      }

      handleArticleSelect({
        ...updatedArticle,
        content: contentWithTitle,
      });
      setSelectedArticleTitle(updatedArticle.title);

      // Update the editor content
      if (editor) {
        editor.commands.setContent(contentWithTitle);
      }
    } catch (error) {
      console.error("Error fetching sub article:", error);
      toast.error("Failed to fetch the sub article");
    }
  };

  const handleClusterArticleClick = async (cluster: ClusterArticleModel) => {
    console.log("Clicked cluster article:", cluster);
    try {
      const updatedCluster = await ApiService.fetchArticle(
        blogId!,
        cluster.id,
        "cluster-articles",
        getAccessTokenSilently
      );

      // Parse the HTML content
      const root = parse(updatedCluster.content || "");
      const existingH1 = root.querySelector("h1");

      let contentWithTitle: string;
      if (
        existingH1 &&
        existingH1.text.trim() === updatedCluster.title.trim()
      ) {
        // If there's already an H1 with the correct title, use the content as is
        contentWithTitle = updatedCluster.content || "";
      } else {
        // If there's no H1 or it doesn't match the title, add one
        contentWithTitle = `<h1>${updatedCluster.title}</h1>${updatedCluster.content || ""}`;
      }

      handleArticleSelect({
        ...updatedCluster,
        content: contentWithTitle,
        metrics: updatedCluster.metrics || [],
      });
      setSelectedArticleTitle(updatedCluster.title);

      // Update the editor content
      if (editor) {
        editor.commands.setContent(contentWithTitle);
      }
    } catch (error) {
      console.error("Error fetching cluster article:", error);
      toast.error("Failed to fetch the cluster article");
    }
  };

  const handleGenerateContent = async () => {
    if (!selectedArticle) return;

    setGenerationStatus("thinking");
    setGenerationMessage("Thinking...");

    const articleType =
      "clusterId" in selectedArticle ? "sub-articles" : "cluster-articles";
    const articleId = selectedArticle.id;
    console.log("Generating content for article:", articleId);
    console.log("Article type:", articleType);

    try {
      await ApiService.generateArticleContent(
        blogId!,
        articleId,
        articleType,
        getAccessTokenSilently
      );
      toast.success("Content generated successfully");
      setGenerationStatus("done");
      setGenerationMessage("");

      // Fetch the updated article data
      const updatedArticle = await ApiService.fetchArticle(
        blogId!,
        articleId,
        articleType,
        getAccessTokenSilently
      );

      // Set the updated article as the selected article
      setSelectedArticle(updatedArticle);

      // Store the current open subblog ID
      const currentOpenSubblogId = openSubblogId;

      // Refetch blog details
      await refetch();

      // Restore the open subblog ID
      setOpenSubblogId(currentOpenSubblogId);
    } catch (error) {
      console.error("Error generating content:", error);
      toast.error("Failed to generate content");
      setGenerationStatus("idle");
      setGenerationMessage("");
    }
  };

  return (
    <Container>
      <IconButton onClick={handleBack}>
        <ArrowBackIcon />
      </IconButton>
      <Typography variant="h5" style={{ marginBottom: "20px" }}>
        {blogTopic}
      </Typography>

      <Grid container spacing={2}>
        <Grid item xs={4}>
          {isLoading && !selectedArticle ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "200px",
              }}
            >
              <CircularProgress />
            </div>
          ) : (
            <>
              {/* Render subblogs, clusters, and articles */}
              {isLoading && !selectedArticle ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "200px",
                  }}
                >
                  <CircularProgress />
                </div>
              ) : blogDetails &&
                blogDetails.subblogs &&
                blogDetails.subblogs.length > 0 ? (
                <SubblogAccordion
                  blogDetails={blogDetails}
                  subblogTitles={subblogTitles}
                  articleTitles={articleTitles}
                  setSubblogTitles={setSubblogTitles}
                  handleClusterArticleClick={handleClusterArticleClick}
                  handleArticleClick={handleArticleClick}
                  deleteClusterArticle={deleteClusterArticle}
                  deleteSubArticle={deleteSubArticle}
                  deleteSubblog={deleteSubblog}
                  handleClusterArticleTitleChange={
                    handleClusterArticleTitleChange
                  }
                  handleSubArticleTitleChange={handleSubArticleTitleChange}
                  selectedArticle={selectedArticle}
                />
              ) : null}
            </>
          )}
        </Grid>
        <Grid item xs={8}>
          <div>
            {editor && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: "10px",
                  fontSize: "14px",
                  color: "#888",
                }}
              >
                <div>
                  {editor.storage.characterCount.characters()} characters |{" "}
                  {editor.storage.characterCount.words()} words
                </div>
                {selectedArticle && (
                  <Button
                    variant="contained"
                    onClick={() => handleNewImage(selectedArticle.title)}
                    disabled={isGeneratingImage}
                    sx={commonStyles.Button}
                  >
                    {isGeneratingImage ? (
                      <>
                        <CircularProgress
                          size={20}
                          color="inherit"
                          style={{ marginRight: "8px" }}
                        />
                        Generating...
                      </>
                    ) : (
                      "New Image"
                    )}
                  </Button>
                )}
              </div>
            )}
            <BlogEditorAppBar
              editor={editor}
              selectedColor={selectedColor}
              setSelectedColor={setSelectedColor}
              handleColorChange={handleColorChange}
              handleFontChange={handleFontChange}
              handleFontSizeChange={handleFontSizeChange}
              handleAddLink={handleAddLink}
              viewMode={viewMode}
              setViewMode={setViewMode}
            />

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                height: "100%",
              }}
            >
              <div style={{ flex: 1, overflow: "auto" }}>
                {viewMode === "rendered" ? (
                  <>
                    {selectedArticle ? (
                      // Render the Editor when an article is selected
                      <EditorContent
                        editor={editor}
                        className="ProseMirror"
                        style={{
                          backgroundColor: "white",
                          padding: "10px",
                          minHeight: "200px",
                          maxHeight: "1000px",
                          overflowY: "auto",
                          marginBottom: "10px",
                          lineHeight: "1.5",
                          outline: "none",
                          border: "1px solid #ccc",
                          borderRadius: "4px",
                        }}
                      />
                    ) : (
                      // Render a placeholder or grayed-out version when no article is selected
                      <div
                        style={{
                          backgroundColor: "#f0f0f0",
                          padding: "10px",
                          minHeight: "200px",
                          maxHeight: "1000px",
                          overflowY: "auto",
                          marginBottom: "10px",
                          lineHeight: "1.5",
                          outline: "none",
                          border: "1px solid #ccc",
                          borderRadius: "4px",
                          color: "#888",
                        }}
                      >
                        No article selected
                      </div>
                    )}
                    {generationStatus === "thinking" && (
                      <div
                        style={{
                          position: "absolute",
                          top: "50%",
                          left: "50%",
                          transform: "translate(-50%, -50%)",
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                        }}
                      >
                        <CircularProgress />
                        <Typography
                          variant="subtitle1"
                          style={{ color: "gray", marginTop: 10 }}
                        >
                          Thinking...
                        </Typography>
                      </div>
                    )}
                  </>
                ) : (
                  <CodeMirror
                    value={
                      viewMode === "raw"
                        ? formattedContent
                        : selectedArticle?.content || ""
                    }
                    extensions={[
                      html({ autoCloseTags: true, matchClosingTags: true }),
                    ]}
                    onChange={(value) => {
                      updateArticleContent(value);
                      autoSaveArticle(selectedArticle!, value);
                    }}
                    style={{
                      height: "auto",
                      maxHeight: "1000px",
                      overflowY: "auto",
                      width: "100%",
                    }}
                  />
                )}
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between", // Aligns buttons to opposite ends
                  marginTop: "10px",
                }}
              >
                <Button
                  variant="contained"
                  startIcon={<AutoFixHighIcon />}
                  onClick={handleGenerateContent}
                  disabled={generationStatus === "thinking"}
                  sx={commonStyles.Button}
                >
                  Generate
                </Button>
                <Button
                  variant="contained"
                  startIcon={<FileDownloadIcon />}
                  onClick={() => handleDownload(selectedArticle, editor)}
                  sx={commonStyles.Button}
                  style={{ marginTop: "10px", alignSelf: "flex-end" }} // Align the button to the right
                >
                  Download
                </Button>
              </div>
            </div>
          </div>

          {selectedArticle && (
            <div style={{ marginTop: "20px" }}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <Card>
                    <CardContent>
                      <Typography variant="h6" gutterBottom>
                        Keyword Details
                      </Typography>
                      <Typography variant="body1">
                        Keyword: {selectedArticle.keyword}
                      </Typography>
                      <Typography variant="body1">
                        Keyword Source: {selectedArticle.keywordSource}
                      </Typography>
                      <Typography variant="body1">
                        Competition Value:{" "}
                        {selectedArticle.keywordCompetitionValue}
                      </Typography>
                      <Typography variant="body1">
                        Average Monthly Searches:{" "}
                        {selectedArticle.keywordAverageMonthlySearches}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
              <Grid container spacing={2} style={{ marginTop: "20px" }}>
                <Grid item>
                  <IconButton
                    onClick={handleShareOnFacebook}
                    title="Share on Facebook"
                  >
                    <FacebookIcon />
                  </IconButton>
                </Grid>
                <Grid item>
                  <IconButton
                    onClick={handleShareViaEmail}
                    title="Share via Email"
                  >
                    <EmailIcon />
                  </IconButton>
                </Grid>
                <Grid item>
                  <IconButton onClick={handleCopyLink} title="Copy Link">
                    <LinkIcon />
                  </IconButton>
                </Grid>
                <Grid item>
                  <IconButton
                    onClick={handleShareOnTwitter}
                    title="Share on Twitter"
                  >
                    <TwitterIcon />
                  </IconButton>
                </Grid>
              </Grid>
            </div>
          )}
        </Grid>
      </Grid>
      {autoSaveStatus !== "idle" && (
        <div
          style={{
            position: "fixed",
            top: "10px",
            right: "10px",
            backgroundColor: "#4caf50",
            color: "white",
            padding: "8px 16px",
            borderRadius: "4px",
          }}
        >
          {autoSaveStatus === "saving" ? "Auto-saving..." : autoSaveMessage}
        </div>
      )}
      <div style={{ marginTop: "20px" }}>
        <Typography variant="h6" gutterBottom>
          Comments
        </Typography>
        {comments.map((comment, index) => (
          <Typography key={index} variant="body1" paragraph>
            {comment}
          </Typography>
        ))}
        <form onSubmit={handleCommentSubmit}>
          <TextField
            label="Add a comment"
            value={newComment}
            onChange={(e) => setNewComment(e.target.value)}
            fullWidth
            margin="normal"
          />
          <Button type="submit" variant="contained" color="primary">
            Submit
          </Button>
        </form>
      </div>
      <ChatbotDialog
        blogId={blogId!}
        selectedArticle={selectedArticle}
        getAccessTokenSilently={getAccessTokenSilently}
        editor={editor}
        autoSaveArticle={autoSaveArticle}
        updateArticleContent={updateArticleContent}
        viewMode={viewMode}
      />
    </Container>
  );
};

export default BlogDetailPage;

// src/index.tsx

import React from "react";
import { createRoot } from "react-dom/client";
import { Auth0Provider } from "@auth0/auth0-react";
import App from "./App";
import "./index.css"; // Import your global CSS here

const container = document.getElementById("root")!;
const root = createRoot(container);

root.render(
  <Auth0Provider
    domain={process.env.REACT_APP_AUTH0_DOMAIN!} // Ensure these are not 'undefined'
    clientId={process.env.REACT_APP_AUTH0_CLIENT_ID!}
    authorizationParams={{
      redirect_uri: window.location.origin,
      audience: "https://api.museoai.com",
    }}
    // Ensure you handle the onRedirectCallback appropriately
    onRedirectCallback={(appState) =>
      window.history.replaceState(
        {},
        document.title,
        appState?.returnTo || window.location.pathname
      )
    }
  >
    <App />
  </Auth0Provider>
);

import React, { useState } from "react";
import {
  Box,
  TextField,
  Grid,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  CircularProgress,
  IconButton,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import { SelectChangeEvent } from "@mui/material/Select";
import { BlogModelPost } from "../../models/BlogModel";
import { ApiService } from "../../services/ApiService";
import { useAuth0 } from "@auth0/auth0-react"; // Import useAuth0
import { ToastContainer, toast } from "react-toastify";

interface BlogFormProps {
  initialFormData: BlogModelPost;
  handleSubmit: (formData: BlogModelPost) => void;
}

const BlogForm: React.FC<BlogFormProps> = ({
  initialFormData,
  handleSubmit,
}) => {
  const { getAccessTokenSilently } = useAuth0(); // Destructure getAccessTokenSilently from useAuth0

  const [formData, setFormData] = useState<BlogModelPost>({
    ...initialFormData,
    companyDescription:
      initialFormData.companyDescription ||
      "The brand Vitable sells nutraceuticals (vitamins, herbs and minerals) for hair growth for women between 18 to 45 years old. In a form of 2 daily capsules. The product targets all the ways your life impacts your hair. Our physician-formulated patented approach multi-targets root causes of thinning hair: we use clinically tested ingredients to target root causes of thinning, such as stress, lifestyle and nutrition. 100% drug-free, with vitamins, minerals, and natural ingredients. Backed by clinical studies so your hair is backed by data. A seamless addition to your daily wellness routine: just 2 pills, once a day for results in 3 to 6 months. Visibly thicker volume. Visibly less shedding. Hair that grows faster, stronger, and longer.",
    topic: initialFormData.topic || "Vitamins",
    country: initialFormData.country || "AU",
    language: initialFormData.language || "en",
    audience: initialFormData.audience || "general",
    tone: initialFormData.tone || "casual",
    companyUrl: "https://www.vitable.com.au/",
    numberOfSubBlogs: initialFormData.numberOfSubBlogs || 1,
    numberOfClusters: initialFormData.numberOfClusters || 10,
    numberOfArticlesPerCluster: initialFormData.numberOfArticlesPerCluster || 2,
    creativityLevel: initialFormData.creativityLevel || 0.5,
    reviewedBy: initialFormData.reviewedBy || "Charles",
    toAvoidPrompt:
      initialFormData.toAvoidPrompt ||
      "Don't mention medical conditions or diseases. Don't mention any company.",
  });

  const [subBlogTopics, setSubBlogTopics] = useState<string[]>([]);
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);

  const handleInputChange = (
    event:
      | React.ChangeEvent<{ name?: string; value: unknown }>
      | SelectChangeEvent<string | number> // Adjusted to handle both string and number types
  ) => {
    const name = event.target.name as keyof BlogModelPost;
    let value: any = event.target.value;

    // Convert to number as needed based on the specific field
    if (
      name === "creativityLevel" ||
      name === "numberOfClusters" ||
      name === "numberOfArticlesPerCluster"
    ) {
      value = Number(value);
    }

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleAddSubblogtopic = () => {
    setSubBlogTopics([...subBlogTopics, ""]);
  };

  const handleRemoveSubblogtopic = (index: number) => {
    const newTopics = subBlogTopics.filter((_, i) => i !== index);
    setSubBlogTopics(newTopics);
  };

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      const topics = await ApiService.generateSubBlogTopics(
        formData,
        getAccessTokenSilently
      );
      setSubBlogTopics(topics);
      setIsDialogOpen(true);
    } catch (error) {
      console.error("Error generating sub-blog topics", error);
    }
  };

  const handleDialogClose = () => {
    setIsDialogOpen(false);
  };

  const handleTopicsChange = (
    index: number,
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const newTopics = [...subBlogTopics];
    newTopics[index] = event.target.value;
    setSubBlogTopics(newTopics);
  };

  const handleTopicsSubmit = async () => {
    setLoading(true);
    try {
      await ApiService.generateBlogStructure(
        formData,
        subBlogTopics,
        getAccessTokenSilently
      );
      setIsDialogOpen(false);
      handleSubmit(formData);

      toast.success("Blog structure generated successfully!");
      setTimeout(() => {
        window.location.reload(); // Refresh the page after a short delay
      }, 1000); // Optional: Delay to show the success message
    } catch (error) {
      console.error("Error generating blog structure", error);
      toast.error("Error generating blog structure");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box sx={{ maxHeight: "80vh", overflowY: "auto", padding: "16px" }}>
      <ToastContainer />
      <Box component="form" noValidate autoComplete="off" onSubmit={onSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Company Description"
              name="companyDescription"
              multiline // Allows for multiple lines of text
              rows={4} // Adjust number of rows as needed
              value={formData.companyDescription}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Company Website"
              name="companyUrl"
              multiline // Allows for multiple lines of text
              rows={1} // Adjust number of rows as needed
              value={formData.companyUrl}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Blog Topic"
              name="topic" // Adjusted to match the BlogFormData interface
              value={formData.topic}
              onChange={handleInputChange}
            />
          </Grid>
          {/* The rest of the form fields are added below */}
          <Grid item xs={4}>
            <TextField
              fullWidth
              label="Subblogs"
              name="numberOfSubBlogs"
              type="number"
              InputProps={{ inputProps: { min: 1 } }}
              value={formData.numberOfSubBlogs.toString()}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              fullWidth
              label="Clusters"
              name="numberOfClusters"
              type="number"
              InputProps={{ inputProps: { min: 1 } }}
              value={formData.numberOfClusters.toString()}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              fullWidth
              label="Articles/Cluster"
              name="numberOfArticlesPerCluster" // Corrected to match the state property name
              type="number"
              InputProps={{ inputProps: { min: 1 } }}
              value={formData.numberOfArticlesPerCluster.toString()}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={4}>
            <FormControl fullWidth>
              <InputLabel id="creativity-level-label">
                Creativity Level
              </InputLabel>
              <Select
                labelId="creativity-level-label"
                name="creativityLevel"
                value={formData.creativityLevel}
                label="Creativity Level"
                onChange={handleInputChange}
              >
                <MenuItem value={0.1}>Low</MenuItem>
                <MenuItem value={0.5}>Medium</MenuItem>
                <MenuItem value={0.7}>High</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={8}>
            <FormControl fullWidth>
              <InputLabel id="country-label">Country</InputLabel>
              <Select
                labelId="country-label"
                name="country"
                value={formData.country}
                label="Country"
                onChange={handleInputChange}
              >
                {/* Countries can be dynamically listed here */}
                <MenuItem value="US">United States</MenuItem>
                <MenuItem value="UK">United Kingdom</MenuItem>
                <MenuItem value="CA">Canada</MenuItem>
                <MenuItem value="AU">Australia</MenuItem>
                {/* Add more countries as needed */}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth>
              <InputLabel id="language-label">Language</InputLabel>
              <Select
                labelId="language-label"
                name="language"
                value={formData.language}
                label="Language"
                defaultValue="en"
                onChange={handleInputChange}
              >
                {/* Languages can be dynamically listed here */}
                <MenuItem value="en">English</MenuItem>
                <MenuItem value="es">Spanish</MenuItem>
                <MenuItem value="fr">French</MenuItem>
                {/* Add more languages as needed */}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth>
              <InputLabel id="audience-label">Audience</InputLabel>
              <Select
                labelId="audience-label"
                name="audience"
                value={formData.audience}
                label="Audience"
                defaultValue="general"
                onChange={handleInputChange}
              >
                {/* Define your audience options here */}
                <MenuItem value="general">General Public</MenuItem>
                <MenuItem value="specialized">Specialized Industry</MenuItem>
                <MenuItem value="professional">Professionals</MenuItem>
                <MenuItem value="academic">Academic</MenuItem>
                {/* Add more audience types as needed */}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel id="tone-label">Tone</InputLabel>
              <Select
                labelId="tone-label"
                name="tone"
                value={formData.tone}
                label="Tone"
                onChange={handleInputChange}
              >
                {/* Define your tone options here */}
                <MenuItem value="informative">Informative</MenuItem>
                <MenuItem value="casual">Casual</MenuItem>
                <MenuItem value="formal">Formal</MenuItem>
                <MenuItem value="persuasive">Persuasive</MenuItem>
                {/* Add more tones as needed */}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Reviewed By"
              name="reviewedBy"
              value={formData.reviewedBy}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Topics to Avoid"
              name="toAvoidPrompt"
              multiline
              rows={4}
              value={formData.toAvoidPrompt}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={12}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                position: "relative",
              }}
            >
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={loading}
              >
                Create
              </Button>
              {loading && (
                <CircularProgress
                  size={24}
                  sx={{
                    color: "primary",
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    marginTop: "-12px",
                    marginLeft: "-12px",
                  }}
                />
              )}
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Dialog
        open={isDialogOpen}
        onClose={handleDialogClose}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle>Edit Sub-Blog Topics</DialogTitle>
        <DialogContent>
          {subBlogTopics.map((topic, index) => (
            <Box
              key={index}
              sx={{ display: "flex", alignItems: "center", mb: 2 }}
            >
              <TextField
                fullWidth
                value={topic}
                onChange={(event) => handleTopicsChange(index, event)}
              />
              <IconButton
                onClick={() => handleRemoveSubblogtopic(index)}
                color="error"
              >
                <DeleteIcon />
              </IconButton>
            </Box>
          ))}
          <Button
            startIcon={<AddIcon />}
            onClick={handleAddSubblogtopic}
            variant="outlined"
            sx={{ mt: 2 }}
          >
            Add Subtopic
          </Button>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose}>Cancel</Button>
          <Button
            onClick={handleTopicsSubmit}
            color="primary"
            disabled={loading}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default BlogForm;

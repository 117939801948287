import { useState, useEffect } from 'react';
import debounce from 'lodash.debounce';

type AutoSaveFunction = (content: string) => Promise<void>;

const useAutoSave = (content: string, autoSaveCallback: AutoSaveFunction) => {
  const [lastSavedContent, setLastSavedContent] = useState(content);

  useEffect(() => {
    const debouncedSave = debounce(async (newContent: string) => {
      if (newContent !== lastSavedContent) {
        await autoSaveCallback(newContent);
        setLastSavedContent(newContent);
      }
    }, 500);

    debouncedSave(content);

    // Cleanup function to clear the debounced function
    return () => {
      debouncedSave.cancel();
    };
  }, [content, autoSaveCallback, lastSavedContent]);
};

export default useAutoSave;

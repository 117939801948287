import React, { useState, useEffect, useRef } from "react";
import {
  Button,
  TextField,
  Typography,
  Fab,
  Zoom,
  Paper,
  IconButton,
  CircularProgress,
  Box,
} from "@mui/material";
import ChatIcon from "@mui/icons-material/Chat";
import CloseIcon from "@mui/icons-material/Close";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import { toast } from "react-toastify";
import { ApiService } from "../../../services/ApiService";
import { SubArticleModel } from "../../../models/SubArticleModel";
import { ClusterArticleModel } from "../../../models/ClusterArticleModel";
import DOMPurify from "dompurify";

interface ChatbotDialogProps {
  blogId: string;
  selectedArticle: SubArticleModel | ClusterArticleModel | null;
  getAccessTokenSilently: () => Promise<string>;
  editor: any;
  autoSaveArticle: (
    article: SubArticleModel | ClusterArticleModel,
    content: string
  ) => Promise<void>;
  updateArticleContent: (content: string) => void;
  viewMode: "raw" | "rendered";
}

const cleanupHtml = (html: string): string => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(html, "text/html");

  // Remove any existing <ul> tags
  doc.querySelectorAll("ul").forEach((ul) => {
    const parent = ul.parentNode;
    if (parent) {
      while (ul.firstChild) {
        parent.insertBefore(ul.firstChild, ul);
      }
      parent.removeChild(ul);
    }
  });

  // Convert improperly formatted list items and wrap them in a new <ul>
  const listItems: Element[] = [];
  doc.body.childNodes.forEach((node) => {
    if (node.nodeType === Node.ELEMENT_NODE) {
      const element = node as Element;
      if (
        element.tagName === "P" &&
        element.textContent?.trim().startsWith("<li>") &&
        element.textContent?.trim().endsWith("</li>")
      ) {
        const li = doc.createElement("li");
        li.innerHTML = (element.textContent.trim().slice(4, -5) || "").trim();
        listItems.push(li);
        element.remove();
      } else if (listItems.length > 0) {
        const ul = doc.createElement("ul");
        listItems.forEach((li) => ul.appendChild(li));
        element.parentNode?.insertBefore(ul, element);
        listItems.length = 0;
      }
    }
  });

  // If there are any remaining list items, append them in a new <ul>
  if (listItems.length > 0) {
    const ul = doc.createElement("ul");
    listItems.forEach((li) => ul.appendChild(li));
    doc.body.appendChild(ul);
  }

  // Sanitize the HTML to prevent XSS attacks
  return DOMPurify.sanitize(doc.body.innerHTML);
};

export const ChatbotDialog: React.FC<ChatbotDialogProps> = ({
  blogId,
  selectedArticle,
  getAccessTokenSilently,
  editor,
  autoSaveArticle,
  updateArticleContent,
  viewMode,
}) => {
  const [isChatbotOpen, setIsChatbotOpen] = useState(false);
  const [chatbotMessage, setChatbotMessage] = useState("");
  const [chatbotResponse, setChatbotResponse] = useState("");
  const [isVisible, setIsVisible] = useState(true);
  const [position, setPosition] = useState({
    x: window.innerWidth - 320,
    y: 100,
  });
  const [size, setSize] = useState({ width: 300, height: 400 });
  const chatbotRef = useRef<HTMLDivElement>(null);
  const resizeRef = useRef<HTMLDivElement>(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.pageYOffset;
      setIsVisible(
        currentScrollPos < 200 ||
          window.innerHeight + currentScrollPos >=
            document.body.offsetHeight - 200
      );
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  useEffect(() => {
    const handleMouseMove = (e: MouseEvent) => {
      if (chatbotRef.current && e.buttons === 1) {
        const newX = e.clientX - chatbotRef.current.offsetWidth / 2;
        const newY = e.clientY - 20;
        setPosition({ x: newX, y: newY });
      }
    };

    const handleMouseUp = () => {
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mouseup", handleMouseUp);
    };

    const handleMouseDown = (e: MouseEvent) => {
      if (
        chatbotRef.current &&
        e.target === chatbotRef.current.querySelector(".drag-handle")
      ) {
        document.addEventListener("mousemove", handleMouseMove);
        document.addEventListener("mouseup", handleMouseUp);
      }
    };

    document.addEventListener("mousedown", handleMouseDown);

    return () => {
      document.removeEventListener("mousedown", handleMouseDown);
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mouseup", handleMouseUp);
    };
  }, []);

  useEffect(() => {
    const handleResize = (e: MouseEvent) => {
      if (resizeRef.current && e.buttons === 1) {
        const newWidth = e.clientX - (position.x + window.pageXOffset);
        const newHeight = e.clientY - (position.y + window.pageYOffset);
        setSize({
          width: Math.max(200, newWidth),
          height: Math.max(200, newHeight),
        });
      }
    };

    const handleMouseUp = () => {
      document.removeEventListener("mousemove", handleResize);
      document.removeEventListener("mouseup", handleMouseUp);
    };

    const handleMouseDown = (e: MouseEvent) => {
      if (resizeRef.current && e.target === resizeRef.current) {
        document.addEventListener("mousemove", handleResize);
        document.addEventListener("mouseup", handleMouseUp);
      }
    };

    document.addEventListener("mousedown", handleMouseDown);

    return () => {
      document.removeEventListener("mousedown", handleMouseDown);
      document.removeEventListener("mousemove", handleResize);
      document.removeEventListener("mouseup", handleMouseUp);
    };
  }, [position]);

  const handleToggleChatbot = () => {
    setIsChatbotOpen(!isChatbotOpen);
    if (!isChatbotOpen) {
      setChatbotResponse(""); // Clear the response when opening the dialog
    }
  };

  const handleSendToChatbot = async () => {
    if (!selectedArticle || isLoading || chatbotMessage.trim() === "") {
      return;
    }
    setIsLoading(true);
    try {
      const rawHtmlContent = selectedArticle.content;

      const response = await ApiService.sendToChatbot(
        blogId,
        selectedArticle.id,
        chatbotMessage,
        getAccessTokenSilently,
        rawHtmlContent
      );

      setChatbotResponse(response.message);

      if (selectedArticle) {
        const cleanedHtml = cleanupHtml(response.updatedContent);

        // Extract the new title from the updated content
        const parser = new DOMParser();
        const doc = parser.parseFromString(cleanedHtml, "text/html");
        const newTitle =
          doc.querySelector("h1")?.textContent || selectedArticle.title;

        const updatedArticle = {
          ...selectedArticle,
          title: newTitle,
          content: cleanedHtml,
        };

        // Update the content based on the current view mode
        if (viewMode === "rendered") {
          editor.commands.setContent(cleanedHtml);
        } else {
          updateArticleContent(cleanedHtml);
        }

        // Update the article with the new title and content
        await autoSaveArticle(updatedArticle, cleanedHtml);
      }
      setChatbotMessage("");
    } catch (error) {
      console.error("Error sending message to chatbot:", error);
      toast.error("Failed to send message to chatbot");
    } finally {
      setIsLoading(false);
    }
  };
  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
      handleSendToChatbot();
    }
  };

  return (
    <>
      <Zoom in={isVisible}>
        <Fab
          color="primary"
          aria-label="chat"
          onClick={handleToggleChatbot}
          style={{
            position: "fixed",
            bottom: "2rem",
            right: "2rem",
            zIndex: 1000,
          }}
        >
          <ChatIcon />
        </Fab>
      </Zoom>
      {isChatbotOpen && (
        <Paper
          ref={chatbotRef}
          elevation={3}
          style={{
            position: "fixed",
            top: `${position.y}px`,
            left: `${position.x}px`,
            width: `${size.width}px`,
            height: `${size.height}px`,
            zIndex: 1000,
            display: "flex",
            flexDirection: "column",
            resize: "both",
            overflow: "auto",
          }}
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            p={1}
            borderBottom="1px solid #e0e0e0"
            className="drag-handle"
            style={{ cursor: "move" }}
          >
            <DragIndicatorIcon fontSize="small" />
            <Typography variant="subtitle2">Chatbot Assistant</Typography>
            <IconButton onClick={handleToggleChatbot} size="small">
              <CloseIcon fontSize="small" />
            </IconButton>
          </Box>
          <Box flexGrow={1} overflow="auto" p={1}>
            {chatbotResponse && (
              <Typography
                variant="body2"
                style={{
                  marginBottom: "0.5rem",
                  whiteSpace: "pre-wrap",
                  wordBreak: "break-word",
                }}
              >
                Chatbot: {chatbotResponse}
              </Typography>
            )}
          </Box>
          <Box p={1} borderTop="1px solid #e0e0e0">
            <TextField
              fullWidth
              size="small"
              variant="outlined"
              placeholder="Type your message..."
              value={chatbotMessage}
              onChange={(e) => setChatbotMessage(e.target.value)}
              style={{ marginBottom: "0.5rem" }}
              multiline
              rows={2}
              onKeyDown={handleKeyDown}
              disabled={isLoading}
            />
            <Button
              fullWidth
              variant="contained"
              color="primary"
              size="small"
              onClick={handleSendToChatbot}
              disabled={isLoading || chatbotMessage.trim() === ""}
              startIcon={
                isLoading ? (
                  <CircularProgress size={20} color="inherit" />
                ) : null
              }
            >
              {isLoading ? "Sending..." : "Send"}
            </Button>
          </Box>
          <div
            ref={resizeRef}
            style={{
              position: "absolute",
              bottom: 0,
              right: 0,
              width: "10px",
              height: "10px",
              cursor: "nwse-resize",
            }}
          />
        </Paper>
      )}
    </>
  );
};

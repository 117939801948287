// SubblogAccordion.tsx
import React, { useState } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  TextField,
  Grid,
  IconButton,
  Card,
  CardContent,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DeleteIcon from "@mui/icons-material/Delete";
import { BlogModelGet } from "../../../models/BlogModel";
import { ClusterArticleModel } from "../../../models/ClusterArticleModel";
import { SubArticleModel } from "../../../models/SubArticleModel";
import commonStyles from "../../../styles/commonStyles";

interface SubblogAccordionProps {
  blogDetails: BlogModelGet | null;
  subblogTitles: { [id: number]: string };
  articleTitles: { [id: number]: string };
  setSubblogTitles: React.Dispatch<
    React.SetStateAction<{ [id: number]: string }>
  >;
  handleClusterArticleClick: (cluster: ClusterArticleModel) => void;
  handleArticleClick: (article: SubArticleModel) => void;
  deleteClusterArticle: (clusterId: number) => Promise<void>;
  deleteSubArticle: (articleId: number) => Promise<void>;
  deleteSubblog: (subblogId: number) => Promise<void>;
  handleClusterArticleTitleChange: (articleId: number, title: string) => void;
  handleSubArticleTitleChange: (articleId: number, title: string) => void;
  selectedArticle: SubArticleModel | ClusterArticleModel | null;
}
const SubblogAccordion: React.FC<SubblogAccordionProps> = ({
  blogDetails,
  subblogTitles,
  setSubblogTitles,
  handleClusterArticleClick,
  handleArticleClick,
  deleteClusterArticle,
  deleteSubArticle,
  deleteSubblog,
  handleClusterArticleTitleChange,
  handleSubArticleTitleChange,
  articleTitles,
  selectedArticle,
}) => {
  const [expandedSubblogs, setExpandedSubblogs] = useState<{
    [id: number]: boolean;
  }>({});

  const handleSubblogToggle =
    (subblogId: number) =>
    (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpandedSubblogs((prev: any) => ({
        ...prev,
        [subblogId]: isExpanded,
      }));
    };
  return (
    <>
      {blogDetails &&
        blogDetails.subblogs &&
        blogDetails.subblogs.map((subblog) => (
          <Accordion
            key={subblog.id}
            expanded={expandedSubblogs[subblog.id] || false}
            onChange={handleSubblogToggle(subblog.id)}
            sx={commonStyles.Accordion}
          >
            <AccordionSummary
              sx={commonStyles.Accordion}
              expandIcon={<ExpandMoreIcon style={{ color: "white" }} />}
            >
              <TextField
                label="Subblog Title"
                fullWidth
                value={subblogTitles[subblog.id] || subblog.title}
                onChange={(e) =>
                  setSubblogTitles((prevTitles) => ({
                    ...prevTitles,
                    [subblog.id]: e.target.value,
                  }))
                }
                onClick={(e) => e.stopPropagation()} // Prevent accordion from toggling when clicking on TextField
                InputLabelProps={{
                  style: { color: "#E8DAFF" },
                }}
                InputProps={{
                  style: { color: "#E8DAFF" },
                }}
                // Using `sx` to style the underline
                sx={{
                  "& .MuiInput-underline:before": {
                    borderBottomColor: "#E8DAFF", // Normal state
                  },
                  "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
                    borderBottomColor: "#E8DAFF", // Hover state
                  },
                  "& .MuiInput-underline:after": {
                    borderBottomColor: "#E8DAFF", // Focused state
                  },
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: "white", // Default state
                    },
                    "&:hover fieldset": {
                      borderColor: "white", // Hover state
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "white", // Focused state
                    },
                  },
                  // Adjusts the input text color
                  "& .MuiInputBase-input": {
                    color: "white",
                  },
                }}
              />
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  deleteSubblog(subblog.id);
                }}
                size="small"
                style={{ color: "white" }}
              >
                <DeleteIcon fontSize="small" />
              </IconButton>
            </AccordionSummary>
            <AccordionDetails>
              {subblog.clusters && subblog.clusters.length > 0
                ? subblog.clusters
                    .sort((a, b) => a.title.localeCompare(b.title))
                    .map((cluster) => (
                      <div key={cluster.id}>
                        <Grid
                          container
                          justifyContent="space-between"
                          alignItems="center"
                        >
                          <Grid
                            item
                            xs={11}
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <Typography
                              variant="subtitle1"
                              component="div"
                              suppressContentEditableWarning
                              onBlur={(e) =>
                                handleClusterArticleTitleChange(
                                  cluster.id,
                                  e.currentTarget.textContent || ""
                                )
                              }
                              sx={{
                                ...commonStyles.Typography,
                                backgroundColor:
                                  selectedArticle &&
                                  selectedArticle.id === cluster.id
                                    ? "rgba(106, 13, 173, 0.1)"
                                    : "inherit",
                                boxShadow:
                                  selectedArticle &&
                                  selectedArticle.id === cluster.id
                                    ? "0 0 4px rgba(106, 13, 173, 0.4)"
                                    : "none",
                                transition:
                                  "background-color 0.3s, box-shadow 0.3s",
                              }}
                              style={{ cursor: "pointer" }}
                              onClick={(event) => {
                                event.stopPropagation(); // Stop the event from bubbling up further
                                handleClusterArticleClick(cluster);
                              }}
                            >
                              {articleTitles[cluster.id] || cluster.title}
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            xs={1}
                            style={{
                              display: "flex",
                              justifyContent: "flex-end",
                            }}
                          >
                            <IconButton
                              onClick={() => deleteClusterArticle(cluster.id)}
                              size="small"
                              style={{ color: "white" }}
                            >
                              <DeleteIcon fontSize="small" />
                            </IconButton>
                          </Grid>
                        </Grid>

                        {cluster.articles && cluster.articles.length > 0
                          ? cluster.articles
                              .sort((a, b) => a.title.localeCompare(b.title))
                              .map((article) => (
                                <Card
                                  key={article.id}
                                  elevation={4}
                                  style={{
                                    margin: "5px 0",
                                    marginBottom: "15px",
                                    backgroundColor: "#E8DAFF",
                                  }}
                                >
                                  <CardContent>
                                    <Grid
                                      container
                                      justifyContent="space-between"
                                      alignItems="center"
                                      wrap="nowrap"
                                    >
                                      <Grid item xs={11}>
                                        <Typography
                                          variant="subtitle2"
                                          component="div"
                                          suppressContentEditableWarning
                                          onBlur={(e) =>
                                            handleSubArticleTitleChange(
                                              article.id,
                                              e.currentTarget.textContent || ""
                                            )
                                          }
                                          style={{
                                            cursor: "pointer",
                                            backgroundColor:
                                              selectedArticle &&
                                              selectedArticle.id === article.id
                                                ? "rgba(106, 13, 173, 0.1)"
                                                : "inherit",
                                            boxShadow:
                                              selectedArticle &&
                                              selectedArticle.id === article.id
                                                ? "0 0 4px rgba(106, 13, 173, 0.4)"
                                                : "none",
                                            transition:
                                              "background-color 0.3s, box-shadow 0.3s",
                                          }}
                                          onClick={() =>
                                            handleArticleClick(article)
                                          }
                                        >
                                          {articleTitles[article.id] ||
                                            article.title}
                                        </Typography>
                                      </Grid>
                                      <Grid item>
                                        <IconButton
                                          onClick={() =>
                                            deleteSubArticle(article.id)
                                          }
                                          size="small"
                                        >
                                          <DeleteIcon
                                            fontSize="small"
                                            style={{
                                              color: "#952EB3",
                                            }}
                                          />
                                        </IconButton>
                                      </Grid>
                                    </Grid>
                                  </CardContent>
                                </Card>
                              ))
                          : null}
                      </div>
                    ))
                : null}
            </AccordionDetails>
          </Accordion>
        ))}
    </>
  );
};

export default SubblogAccordion;

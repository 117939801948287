import React, { useState, useEffect, useRef, useCallback } from "react";
import { useAuth0 } from "@auth0/auth0-react";

import {
  Modal,
  Box,
  Grid,
  Container,
  Button,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  CircularProgress,
} from "@mui/material";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import BlogForm from "../Blog/BlogForm"; // Make sure the path matches your file structure
import { BlogCard } from "../Blog/BlogCard";
import MenuIcon from "@mui/icons-material/Menu"; // Import the Menu icon for the hamburger menu
import { BlogModelGet, BlogModelPost } from "../../models/BlogModel";
import { ApiService } from "../../services/ApiService";

const Dashboard: React.FC = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { getAccessTokenSilently } = useAuth0();
  const [isLoading, setIsLoading] = useState(true);
  const [blogs, setBlogs] = useState<BlogModelGet[]>([]);
  const websocketRef = useRef<WebSocket | null>(null);
  const [showOverlay, setShowOverlay] = useState(false); // New state for overlay visibility
  const [token, setToken] = useState<string | null>(null); // State to store the obtained token
  const [progress, setProgress] = useState(0); // Initialize progress to 0
  const [, setSubBlogTopics] = useState<string[]>([]);
  const [, setIsDialogOpen] = useState<boolean>(false);

  const [formData] = useState<BlogModelPost>({
    topic: "Hair Growth",
    country: "AU",
    language: "en",
    numberOfClusters: 10,
    numberOfArticlesPerCluster: 2,
    numberOfSubBlogs: 1,
    creativityLevel: 0.5,
    companyDescription:
      "The brand Vitable sells nutraceuticals (vitamins, herbs and minerals) for hair growth for women between 18 to 45 years old. In a form of 2 daily capsules. The product targets all the ways your life impacts your hair. Our physician-formulated patented approach multi-targets root causes of thinning hair: we use clinically tested ingredients to target root causes of thinning, such as stress, lifestyle and nutrition. 100% drug-free, with vitamins, minerals, and natural ingredients. Backed by clinical studies so your hair is backed by data. A seamless addition to your daily wellness routine: just 2 pills, once a day for results in 3 to 6 months. Visibly thicker volume. Visibly less shedding. Hair that grows faster, stronger, and longer.",
    companyUrl: "http://www.example.com",
    audience: "general",
    tone: "casual",
    createdAt: new Date(),
    editedAt: new Date(),
    toAvoidPrompt:
      "Don't mention medical conditions or diseases. Don't mention any company.",
    toIncludePrompt: "",
    reviewedBy: "Dr John Doe",
  });
  const { logout } = useAuth0();

  useEffect(() => {
    const fetchToken = async () => {
      try {
        const accessToken = await getAccessTokenSilently();
        setToken(accessToken); // This sets the token in state once it's obtained
      } catch (e) {
        console.error("Error obtaining access token", e);
      }
    };

    fetchToken();
  }, [getAccessTokenSilently]); // Fetch token on component mount or when getAccessTokenSilently changes
  useEffect(() => {
    const sendHeartbeat = () => {
      if (websocketRef.current?.readyState === WebSocket.OPEN) {
        websocketRef.current.send(JSON.stringify({ type: "heartbeat" }));
      }
    };

    const heartbeatInterval = setInterval(sendHeartbeat, 30000); // Send heartbeat every 30 seconds

    return () => clearInterval(heartbeatInterval);
  }, []);

  const fetchBlogs = useCallback(async () => {
    setIsLoading(true); // Start loading before the request
    try {
      const response = await ApiService.fetchBlogs(getAccessTokenSilently);

      // Ensure createdAt and editedAt are Date objects
      const blogsWithDateObjects = response.map((blog) => ({
        ...blog,
        createdAt: new Date(blog.createdAt),
        editedAt: new Date(blog.editedAt),
      }));

      // Sort blogs by most recent first
      blogsWithDateObjects.sort(
        (a, b) => b.createdAt.getTime() - a.createdAt.getTime()
      );

      setBlogs(blogsWithDateObjects); // Set fetched blogs
    } catch (error) {
      console.error("Failed to fetch blogs", error);
      // Optionally, handle error state/UI here
    } finally {
      setIsLoading(false); // Ensure loading is stopped in both success and error cases
    }
  }, [getAccessTokenSilently]); // Dependencies

  useEffect(() => {
    if (token) {
      fetchBlogs(); // Fetch blogs when the token is available
      // Only proceed if the token is available
      websocketRef.current = new WebSocket(process.env.REACT_APP_WS_URL!);

      websocketRef.current.onopen = () => {
        // Check if the websocket connection and token are available before sending the token
        if (websocketRef.current && token) {
          websocketRef.current.send(JSON.stringify({ token: token }));
        }
      };

      websocketRef.current.onmessage = (event) => {
        const message = JSON.parse(event.data);
        if (message.progress !== undefined) {
          setProgress(message.progress); // Update progress state
          console.log("PROGRESS", message.progress);
          if (message.progress === 10) {
            fetchBlogs();
          }
          toast.info(`Progress: ${message.progress}%`); // Optional: Display progress as a toast
        } else {
          toast.info(message); // Handle other messages
        }
      };

      // Return a cleanup function to close the websocket connection when the component unmounts or token changes
      return () => {
        websocketRef.current?.close();
      };
    }
  }, [token, fetchBlogs]); // This useEffect depends on the token

  // Function to submit a new blog
  const handleSubmit = async (formData: BlogModelPost) => {
    setIsModalOpen(false); // Close the modal immediately
    toast.info("Generating sub-blog topics...", { autoClose: 2000 }); // Show a toast message

    try {
      const topics = await ApiService.generateSubBlogTopics(
        formData,
        getAccessTokenSilently
      );
      setSubBlogTopics(topics);
      setIsDialogOpen(true);
    } catch (error) {
      console.error("Error generating sub-blog topics", error);
      toast.error("Failed to generate sub-blog topics.", { autoClose: 2000 });
    }
  };

  // Function to handle blog deletion
  const handleEdit = async (blogId: string, newTitle: string) => {
    try {
      await ApiService.editBlog(
        blogId,
        { title: newTitle },
        getAccessTokenSilently
      );
      toast.success("Blog updated successfully!", { autoClose: 2000 });
      fetchBlogs(); // Refresh the blogs list after successful update
    } catch (error) {
      console.error("Error updating blog title", error);
      toast.error("Failed to update blog title.", { autoClose: 2000 });
    }
  };

  const handleDelete = async (blogId: string) => {
    try {
      await ApiService.deleteBlog(blogId, getAccessTokenSilently);
      toast.success("Blog deleted successfully!", { autoClose: 2000 });
      fetchBlogs(); // Refresh the blogs list
      // Prevent redirection here if needed, though stopping propagation in BlogCard should suffice
    } catch (error) {
      console.error("Failed to delete blog", error);
      toast.error("Failed to delete blog.", { autoClose: 2000 });
    }
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setShowOverlay(false); // Hide overlay when modal is closed
  };

  return (
    <>
      <AppBar position="static" elevation={0} color="transparent">
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            variant="h6"
            component="div"
            sx={{
              flexGrow: 1,
              fontFamily: "'Montserrat', sans-serif",
              color: "#1976D2",
            }}
          >
            Dashboard
          </Typography>
          <Button
            variant="contained"
            onClick={() => setIsModalOpen(true)}
            style={{
              fontFamily: "'Montserrat', sans-serif",
              background: "linear-gradient(to right, #1976D2, #9C26B0)",
              marginRight: "10px", // Add some space between the buttons
            }}
          >
            Create New Blog
          </Button>
          <Button
            onClick={() =>
              logout({ logoutParams: { returnTo: window.location.origin } })
            }
            variant="contained"
            sx={{
              fontFamily: "'Montserrat', sans-serif",
              background: "linear-gradient(to right, #1976D2, #9C26B0)",
            }}
          >
            Log Out
          </Button>
        </Toolbar>
      </AppBar>

      {showOverlay && (
        <Box
          sx={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0,0,0,0.5)",
            zIndex: 999,
          }}
        ></Box>
      )}
      <Container
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",

          alignItems: "center",
          minHeight: "100vh", // Use minHeight to ensure it can grow
          position: "relative", // Relative position for absolute child positioning
        }}
      >
        <Box
          sx={{
            borderRadius: 4,
            padding: 10,
            background: "#F3E5F5",
            position: "relative", // Ensure this Box can serve as a reference for the button's positioning
            paddingBottom: "70px", // Add padding at the bottom to ensure there's space for the button
            boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)",
            backdropFilter: "blur(5px)",
            overflow: "auto",
            maxHeight: "90vh",
            width: "100%",
            "&::-webkit-scrollbar": {
              width: "10px",
            },
            "&::-webkit-scrollbar-track": {
              background: "#f1f1f1",
            },
            "&::-webkit-scrollbar-thumb": {
              background: "#fff",
            },
            "&::-webkit-scrollbar-thumb:hover": {
              background: "#d1d1d1",
            },
            scrollbarColor: "white #f1f1f1",
            scrollbarWidth: "thin",
          }}
        >
          {isLoading ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
              }}
            >
              <CircularProgress />
            </Box>
          ) : (
            <>
              <Grid container justifyContent="flex-start" spacing={4}>
                {blogs.map((blog) => (
                  <Grid item key={blog.id} xs={12} sm={6} md={4} lg={3}>
                    <Link
                      to={`/blog/${blog.id}`}
                      style={{ textDecoration: "none" }}
                    >
                      <BlogCard
                        blog={blog}
                        onEdit={handleEdit}
                        onDelete={handleDelete}
                        progress={progress}
                      />
                    </Link>
                    {!blog.subblogs && (
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          mt: 2,
                        }}
                      >
                        <CircularProgress />
                      </Box>
                    )}
                  </Grid>
                ))}
              </Grid>
            </>
          )}
        </Box>
      </Container>
      <Modal
        open={isModalOpen}
        onClose={closeModal}
        closeAfterTransition
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
            borderRadius: "16px",
          }}
        >
          <BlogForm initialFormData={formData} handleSubmit={handleSubmit} />
        </Box>
      </Modal>
    </>
  );
};

export default Dashboard;

import React, { useState } from "react";
import { Chip, Divider } from "@mui/material";
import { BlogModelGet } from "../../models/BlogModel";
import MoreVertIcon from "@mui/icons-material/MoreVert"; // For the menu icon
import {
  Card,
  CardContent,
  Box,
  Typography,
  IconButton,
  Menu,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Button,
} from "@mui/material";

interface BlogCardProps {
  blog: BlogModelGet;
  onEdit: (blogId: string, title: string) => void;
  onDelete: (blogId: string) => void;
  progress?: number; // Optional prop to represent current progress
}

// Adjusted formatDate function
function formatDate(date: Date | string): string {
  const options: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  };

  const dateObj = typeof date === "string" ? new Date(date) : date;
  return dateObj.toLocaleString(undefined, options);
}

export const BlogCard: React.FC<BlogCardProps> = ({
  blog,
  onEdit,
  onDelete,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [editTopic, setEditTopic] = useState(blog.topic);

  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault(); // Prevent default action
    event.stopPropagation(); // Stop event propagation
    setAnchorEl(event.currentTarget);
  };

  const openEditModal = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault(); // Prevent the default action
    event.stopPropagation(); // Stop the event from propagating up to the parent link
    setEditModalOpen(true);
    handleCloseMenu();
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleEditSubmit = async (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault(); // Prevent the default action
    event.stopPropagation(); // Stop the event from propagating up to the parent link

    await onEdit(blog.id, editTopic); // Call the onEdit function with the blog id and new title
    setEditModalOpen(false); // Close the modal after submitting
  };
  return (
    <Card
      sx={{
        maxWidth: 400, // Increased from 345 to 400 for wider cards
        width: 270, // Explicitly setting width for consistency
        height: 450, // Increased height for more space
        borderRadius: "16px",
        position: "relative",
        margin: "10px",
        fontFamily: "'Montserrat', sans-serif",
        // backgroundImage: "linear-gradient(to right, #9C27B0, #CE93D8)",
        background: "#CE93D8",
        color: "white",
        border: "1px solid #CE93D8",
        boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
        transition: "transform 0.3s ease-in-out",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between", // Adjust as needed
        minHeight: "auto", // Adjust based on your content or remove it
        "&:hover": {
          transform: "scale(1.05)",
          cursor: "pointer",
        },
      }}
    >
      <Box
        sx={{
          backgroundColor: "rgba(255, 255, 255, 0.2)",
          padding: "10px",
          borderRadius: "16px 16px 0 0",
        }}
      >
        <Typography
          gutterBottom
          variant="h6"
          component="div"
          sx={{ fontFamily: "'Montserrat', sans-serif" }}
        >
          {blog.topic}
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            mt: 1,
          }}
        >
          <Typography
            variant="caption"
            sx={{ fontFamily: "'Montserrat', sans-serif", color: "white" }}
          >
            Created: {formatDate(blog.createdAt)}
          </Typography>
          <Typography
            variant="caption"
            sx={{ fontFamily: "'Montserrat', sans-serif", color: "white" }}
          >
            Edited: {blog.editedAt ? formatDate(blog.editedAt) : "N/A"}
          </Typography>
        </Box>
      </Box>
      <CardContent sx={{ overflow: "auto", flexGrow: 1 }}>
        <Typography variant="body2" color="white">
          Topic: {blog.topic || "N/A"}
        </Typography>
        <Typography variant="body2" color="white">
          Country: {blog.country || "N/A"}
        </Typography>
        <Typography variant="body2" color="white">
          Language: {blog.language || "N/A"}
        </Typography>
        <Typography variant="body2" color="white">
          Subblogs: {blog.numberOfSubBlogs || "N/A"}
        </Typography>
        <Typography variant="body2" color="white">
          Clusters: {blog.numberOfClusters || "N/A"}
        </Typography>
        <Typography variant="body2" color="white">
          Articles/Cluster: {blog.numberOfArticlesPerCluster || "N/A"}
        </Typography>
        <Typography variant="body2" color="white">
          Creativity Level: {blog.creativityLevel || "N/A"}
        </Typography>

        {/* Additional Information */}
        <Box sx={{ my: 2 }}>
          <Typography variant="body2" color="white">
            Reviewed By: {blog.reviewedBy || "N/A"}
          </Typography>
          <Typography variant="body2" color="white">
            To Avoid: {blog.toAvoidPrompt || "N/A"}
          </Typography>
        </Box>

        {/* Company Description */}
        <Box sx={{ my: 2, maxHeight: 50, overflow: "auto" }}>
          <Typography variant="subtitle1" gutterBottom component="div">
            Company Description
          </Typography>
          <Typography variant="body2" color="white">
            {blog.companyDescription}
          </Typography>
        </Box>
        <Box sx={{ my: 1, maxHeight: 30, overflow: "auto" }}>
          <Typography variant="body2" color="blue">
            {blog.companyUrl}
          </Typography>
        </Box>

        {/* Audience and Tone */}
        <Box sx={{ my: 2 }}>
          <Chip
            label={`Audience: ${blog.audience || "General"}`}
            color="primary"
            sx={{ mb: 1, mr: 1 }}
          />
          <Chip label={`Tone: ${blog.tone || "Neutral"}`} color="secondary" />
        </Box>

        <Divider sx={{ my: 2, backgroundColor: "rgba(255,255,255,0.2)" }} />
      </CardContent>
      {/* Menu Icon Button */}
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleOpenMenu}
        sx={{ position: "absolute", right: 8, top: 8 }}
      >
        <MoreVertIcon />
      </IconButton>

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
      >
        <MenuItem onClick={openEditModal}>Edit</MenuItem>
        <MenuItem
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            if (window.confirm("Are you sure you want to delete this blog?")) {
              onDelete(blog.id);
            }
          }}
        >
          Delete
        </MenuItem>
      </Menu>
      {/* Edit Modal */}
      <Dialog open={editModalOpen} onClose={() => setEditModalOpen(false)}>
        <DialogTitle>Edit Blog Title</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="title"
            label="Title"
            type="text"
            fullWidth
            variant="standard"
            value={editTopic}
            onChange={(e) => setEditTopic(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setEditModalOpen(false);
            }}
          >
            Cancel
          </Button>
          <Button onClick={handleEditSubmit}>Save</Button>
        </DialogActions>
      </Dialog>
    </Card>
  );
};

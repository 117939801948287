// src/services/ApiService.ts
import axios from 'axios';
import { BlogModelPost, BlogModelGet } from "../models/BlogModel";
import { SubArticleModel } from '../models/SubArticleModel';
import { ClusterArticleModel } from '../models/ClusterArticleModel';

const API_URL = process.env.REACT_APP_API_URL;

const axiosInstance = axios.create({
  baseURL: API_URL,
  timeout: 6000000, // Set the timeout to 600000 milliseconds (10 minutes)
});
// Assuming getAccessTokenSilently is a function from Auth0
export const getAuthToken = async (getAccessTokenSilently: () => Promise<string>): Promise<string> => {
  try {
    const token = await getAccessTokenSilently();
    return token;
  } catch (error) {
    console.error('Error getting the access token', error);
    throw new Error('Failed to get access token');
  }
};

export const ApiService = {
  async fetchBlogs(getAccessTokenSilently: () => Promise<string>): Promise<BlogModelGet[]> {
    const token = await getAuthToken(getAccessTokenSilently);
    const response = await axiosInstance.get<BlogModelGet[]>(`${API_URL}/blogs`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  },
  async generateSubBlogTopics(formData: BlogModelPost, getAccessTokenSilently: () => Promise<string>): Promise<string[]> {
    const token = await getAccessTokenSilently();
    const response = await axiosInstance.post<string[]>(`${API_URL}/blogs/generate-subblog-topics`, formData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  },

  async generateBlogStructure(formData: BlogModelPost, subBlogTopics: string[], getAccessTokenSilently: () => Promise<string>): Promise<void> {
    const token = await getAccessTokenSilently();
    await axiosInstance.post(`${API_URL}/blogs/generate-blog-structure`, {
        blog_data: formData, // Ensure blog_data is correctly included
        sub_blog_topics: subBlogTopics
    }, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
},
  async deleteBlog(blogId: string, getAccessTokenSilently: () => Promise<string>): Promise<void> {
    const token = await getAuthToken(getAccessTokenSilently);
    await axiosInstance.delete(`${API_URL}/blogs/${blogId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  },

  async editBlog(blogId: string, newTitle: { title: string }, getAccessTokenSilently: () => Promise<string>): Promise<void> {
    const token = await getAuthToken(getAccessTokenSilently);
    await axiosInstance.patch(`${API_URL}/blogs/${blogId}`, newTitle, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  },
  async fetchBlogDetails(blogId: string, getAccessTokenSilently: () => Promise<string>): Promise<BlogModelGet> {
    const token = await getAuthToken(getAccessTokenSilently);
    const response = await axiosInstance.get<BlogModelGet>(`${API_URL}/blogs/${blogId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  },

  async autoSaveSubArticle(blogId: string, articleId: number, articleData: Partial<SubArticleModel>, getAccessTokenSilently: () => Promise<string>): Promise<{ message: string }> {
    const token = await getAuthToken(getAccessTokenSilently);
    const response = await axiosInstance.patch<{ message: string }>(`${API_URL}/blogs/${blogId}/sub-articles/${articleId}/autosave`, articleData, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
  },

  async autoSaveClusterArticle(blogId: string, articleId: number, articleData: Partial<ClusterArticleModel>, getAccessTokenSilently: () => Promise<string>): Promise<{ message: string }> {
    const token = await getAuthToken(getAccessTokenSilently);
    const response = await axiosInstance.patch<{ message: string }>(`${API_URL}/blogs/${blogId}/cluster-articles/${articleId}/autosave`, articleData, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
  },

  async saveSubArticle(blogId: string, articleId: number, articleData: Partial<SubArticleModel>, getAccessTokenSilently: () => Promise<string>): Promise<void> {
    const token = await getAuthToken(getAccessTokenSilently);
    await axiosInstance.patch(`${API_URL}/blogs/${blogId}/sub-articles/${articleId}`, articleData, {
      headers: { Authorization: `Bearer ${token}` },
    });
  },

  async saveClusterArticle(blogId: string, articleId: number, articleData: Partial<ClusterArticleModel>, getAccessTokenSilently: () => Promise<string>): Promise<void> {
    const token = await getAuthToken(getAccessTokenSilently);
    await axiosInstance.patch(`${API_URL}/blogs/${blogId}/cluster-articles/${articleId}`, articleData, {
      headers: { Authorization: `Bearer ${token}` },
    });
  },

  async editSubblog(
    blogId: string,
    subblogId: number,
    newTitle: { title: string },
    getAccessTokenSilently: () => Promise<string>
  ): Promise<void> {
    const token = await getAuthToken(getAccessTokenSilently);
    await axiosInstance.patch(`${API_URL}/blogs/${blogId}/subblogs/${subblogId}`, newTitle, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  },

  async deleteSubArticle(blogId: string, articleId: number, getAccessTokenSilently: () => Promise<string>): Promise<void> {
    const token = await getAuthToken(getAccessTokenSilently);
    await axiosInstance.delete(`${API_URL}/blogs/${blogId}/sub-articles/${articleId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  },
  
  async deleteClusterArticle(blogId: string, clusterArticleId: number, getAccessTokenSilently: () => Promise<string>): Promise<void> {
    const token = await getAuthToken(getAccessTokenSilently);
    await axiosInstance.delete(`${API_URL}/blogs/${blogId}/cluster-articles/${clusterArticleId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  },

  async uploadImage(blogId: string, articleTitle: string, imageFile: File, getAccessTokenSilently: () => Promise<string>): Promise<string> {
    const token = await getAuthToken(getAccessTokenSilently);
    const formData = new FormData();
    formData.append('blog_id', blogId);
    formData.append('article_title', articleTitle);
    formData.append('image', imageFile);

    const response = await axiosInstance.post<{ url: string }>(`${API_URL}/upload-image`, formData, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'multipart/form-data',
      },
    });

    return response.data.url;
  },
  async generateArticleContent(blogId: string, articleId: number, articleType: string, getAccessTokenSilently: () => Promise<string>) {
    const token = await getAuthToken(getAccessTokenSilently);
    const url = `${API_URL}/blogs/${blogId}/${articleType}/${articleId}/generate`;
    const response = await axiosInstance.patch(url, null, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
},
async deleteSubblog(blogId: string, subblogId: number, getAccessTokenSilently: () => Promise<string>): Promise<void> {
  const token = await getAuthToken(getAccessTokenSilently);
  await axiosInstance.delete(`${API_URL}/blogs/${blogId}/subblogs/${subblogId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
},

// src/services/ApiService.ts
async fetchArticle(blogId: string, articleId: number, articleType: 'sub-articles' | 'cluster-articles', getAccessTokenSilently: () => Promise<string>): Promise<SubArticleModel | ClusterArticleModel> {
  const token = await getAuthToken(getAccessTokenSilently);
  const response = await axiosInstance.get<SubArticleModel | ClusterArticleModel>(`${API_URL}/blogs/${blogId}/${articleType}/${articleId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
},

async regenerateArticleImage(title: string, getAccessTokenSilently: () => Promise<string>) {
  const token = await getAuthToken(getAccessTokenSilently);
  const response = await axiosInstance.post(`${API_URL}/regenerate-image/${title}`, {}, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data; // Assuming it returns the new image URL
},
async sendToChatbot(
  blogId: string,
  articleId: number,
  message: string,
  getAccessTokenSilently: () => Promise<string>,
  rawHtmlContent: string
) {
  const token = await getAuthToken(getAccessTokenSilently);
  const response = await axiosInstance.post(
    `${API_URL}/blogs/${blogId}/articles/${articleId}/chatbot`,
    { message, rawHtmlContent },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

  if (response.status !== 200) {
    throw new Error('Failed to send message to chatbot');
  }

  return response.data; // This should include the raw HTML content
},
};

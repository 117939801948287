// src/styles/commonStyles.ts
import { SxProps, Theme } from '@mui/system';

const commonStyles: { [key: string]: SxProps<Theme> } = {
  Accordion: {
    backgroundColor: "#CE93D8",
    color: "white",
    margin: "10px 0",
    borderRadius: "8px",
    boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
  },
  Typography: {
    fontFamily: "'Montserrat', sans-serif",
    color: "white",
  },
  Button: {
    background: "linear-gradient(to right, #1976D2, #9C26B0)",
    color: "white",
    '&:hover': {
      backgroundColor: "#7B1FA2",
    },
    margin: "10px 0",
  },
  TextField: {
    // Define TextField styles if needed
  },
  // Extend with more styles as needed
};

export default commonStyles;

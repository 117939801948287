// BlogEditorAppBar.tsx
import React from "react";
import {
  AppBar,
  Toolbar,
  IconButton,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import FormatPaintIcon from "@mui/icons-material/FormatPaint";
import FormatBoldIcon from "@mui/icons-material/FormatBold";
import FormatItalicIcon from "@mui/icons-material/FormatItalic";
import FormatUnderlinedIcon from "@mui/icons-material/FormatUnderlined";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import FormatListNumberedIcon from "@mui/icons-material/FormatListNumbered";
import FormatQuoteIcon from "@mui/icons-material/FormatQuote";
import LinkIcon from "@mui/icons-material/Link";
import FormatAlignLeftIcon from "@mui/icons-material/FormatAlignLeft";
import FormatAlignCenterIcon from "@mui/icons-material/FormatAlignCenter";
import FormatAlignRightIcon from "@mui/icons-material/FormatAlignRight";
import FormatAlignJustifyIcon from "@mui/icons-material/FormatAlignJustify";
import VisibilityIcon from "@mui/icons-material/Visibility";
import CodeIcon from "@mui/icons-material/Code";
import { Editor } from "@tiptap/react";

interface BlogEditorAppBarProps {
  editor: Editor | null;
  selectedColor: string;
  setSelectedColor: React.Dispatch<React.SetStateAction<string>>;
  handleColorChange: (color: string) => void;
  handleFontChange: (fontFamily: string) => void;
  handleFontSizeChange: (fontSize: string) => void;
  handleAddLink: () => void;
  viewMode: "raw" | "rendered";
  setViewMode: React.Dispatch<React.SetStateAction<"raw" | "rendered">>;
}

const BlogEditorAppBar: React.FC<BlogEditorAppBarProps> = ({
  editor,
  selectedColor,
  setSelectedColor,
  handleColorChange,
  handleFontChange,
  handleFontSizeChange,
  handleAddLink,
  viewMode,
  setViewMode,
}) => {
  const colorPickerRef = React.useRef<HTMLInputElement>(null);

  return (
    <AppBar
      position="static"
      color="default"
      elevation={1}
      sx={{ minWidth: "100%" }}
    >
      <Toolbar variant="dense">
        {/* Text formatting buttons */}
        <div style={{ marginLeft: "-10px" }}>
          <IconButton
            onClick={() => colorPickerRef.current?.click()}
            style={{ zIndex: 1 }}
          >
            <FormatPaintIcon />
          </IconButton>
          <input
            ref={colorPickerRef}
            type="color"
            value={selectedColor}
            onChange={(e) => {
              setSelectedColor(e.target.value);
              handleColorChange(e.target.value);
            }}
            style={{
              position: "absolute",
              top: "0",
              left: "0",
              opacity: 0, // Make the input invisible, but clickable
              width: "48px", // Adjust width and height to cover the IconButton area
              height: "48px",
              zIndex: 2, // Ensure the input is above the IconButton
            }}
          />
        </div>
        <IconButton onClick={() => editor?.chain().focus().toggleBold().run()}>
          <FormatBoldIcon />
        </IconButton>
        <IconButton
          onClick={() => editor?.chain().focus().toggleItalic().run()}
        >
          <FormatItalicIcon />
        </IconButton>
        <IconButton
          onClick={() => editor?.chain().focus().toggleUnderline().run()}
        >
          <FormatUnderlinedIcon />
        </IconButton>

        {/* Font family selection */}
        <FormControl size="small" style={{ minWidth: 120, marginLeft: 10 }}>
          <InputLabel>Font</InputLabel>
          <Select
            onChange={(e) => handleFontChange(e.target.value)}
            label="Font"
            defaultValue="Arial" // Add a default value
          >
            <MenuItem value="Arial">Arial</MenuItem>
            <MenuItem value="Georgia">Georgia</MenuItem>
            <MenuItem value="Times New Roman">Times New Roman</MenuItem>
          </Select>
        </FormControl>

        {/* Font size selection */}
        <FormControl size="small" style={{ minWidth: 90, marginLeft: 10 }}>
          <InputLabel>Size</InputLabel>
          <Select
            onChange={(e) => handleFontSizeChange(e.target.value.toString())}
            label="Size"
            defaultValue={12} // Add a default value
          >
            <MenuItem value={6}>6</MenuItem>
            <MenuItem value={8}>8</MenuItem>
            <MenuItem value={10}>10</MenuItem>
            <MenuItem value={12}>12</MenuItem>
            <MenuItem value={14}>14</MenuItem>
            <MenuItem value={16}>16</MenuItem>
            <MenuItem value={18}>18</MenuItem>
            <MenuItem value={20}>20</MenuItem>
            <MenuItem value={26}>26</MenuItem>
            <MenuItem value={30}>30</MenuItem>
            <MenuItem value={40}>40</MenuItem>
            <MenuItem value={60}>60</MenuItem>
          </Select>
        </FormControl>

        {/* List and alignment buttons */}
        <IconButton
          onClick={() => editor?.chain().focus().toggleBulletList().run()}
        >
          <FormatListBulletedIcon />
        </IconButton>
        <IconButton
          onClick={() => editor?.chain().focus().toggleOrderedList().run()}
        >
          <FormatListNumberedIcon />
        </IconButton>
        <IconButton
          onClick={() => editor?.chain().focus().toggleBlockquote().run()}
        >
          <FormatQuoteIcon />
        </IconButton>
        <IconButton onClick={() => handleAddLink()}>
          <LinkIcon />
        </IconButton>
        <IconButton
          onClick={() => editor?.chain().focus().setTextAlign("left").run()}
        >
          <FormatAlignLeftIcon />
        </IconButton>
        <IconButton
          onClick={() => editor?.chain().focus().setTextAlign("center").run()}
        >
          <FormatAlignCenterIcon />
        </IconButton>
        <IconButton
          onClick={() => editor?.chain().focus().setTextAlign("right").run()}
        >
          <FormatAlignRightIcon />
        </IconButton>
        <IconButton
          onClick={() => editor?.chain().focus().setTextAlign("justify").run()}
        >
          <FormatAlignJustifyIcon />
        </IconButton>

        <div style={{ marginLeft: "0px" }}>
          <IconButton
            onClick={() => setViewMode(viewMode === "raw" ? "rendered" : "raw")}
            title={viewMode === "raw" ? "View Rendered" : "View Raw"}
          >
            {viewMode === "raw" ? <VisibilityIcon /> : <CodeIcon />}
          </IconButton>
        </div>
      </Toolbar>
    </AppBar>
  );
};

export default BlogEditorAppBar;

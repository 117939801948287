import { Editor } from "@tiptap/react";
import { ClusterArticleModel } from "../../../../models/ClusterArticleModel";
import { SubArticleModel } from "../../../../models/SubArticleModel";

export const handleDownload = (selectedArticle: SubArticleModel | ClusterArticleModel | null, editor: Editor | null) => {
  if (!selectedArticle) return;
  const element = document.createElement("a");
  const file = new Blob([editor?.getHTML() || ""], { type: "text/html" });
  element.href = URL.createObjectURL(file);
  element.download = `${selectedArticle.title}.html`;

  // Create a new document and write the HTML content
  const newDoc = document.implementation.createHTMLDocument();
  newDoc.documentElement.innerHTML = editor?.getHTML() || "";

  // Remove <p> tags inside table cells and replace them with <div> tags or just the inner content
  const tableCells = newDoc.querySelectorAll("td, th");
  tableCells.forEach((cell) => {
    const paragraphs = cell.querySelectorAll("p");
    paragraphs.forEach((p) => {
      const div = newDoc.createElement("div");
      div.innerHTML = p.innerHTML;
      p.parentNode?.replaceChild(div, p);
    });
  });

  // Wrap the content inside a container div
  const containerDiv = newDoc.createElement("div");
  containerDiv.className = "ProseMirror-container";
  while (newDoc.body.firstChild) {
    containerDiv.appendChild(newDoc.body.firstChild);
  }
  newDoc.body.appendChild(containerDiv);

 
  // Update the file content with the modified HTML
  const modifiedFile = new Blob([newDoc.documentElement.outerHTML], {
    type: "text/html",
  });

  // Preserve image position styles
  const images = newDoc.querySelectorAll("img");
  images.forEach((img) => {
    const left = img.style.left;
    const top = img.style.top;
    if (left) {
      img.style.left = left;
    }
    if (top) {
      img.style.top = top;
    }
  });
  element.href = URL.createObjectURL(modifiedFile);

  document.body.appendChild(element);
  element.click();
  document.body.removeChild(element);
};
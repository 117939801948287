export function formatHtml(html: string) {
    const inlineTags = [
      "a",
      "span",
      "strong",
      "em",
      "b",
      "i",
      "u",
      "small",
      "sub",
      "sup",
    ];
    let result = "";
    let indentLevel = 0;
    const tokens = html.match(/<\/?[^>]+>|[^<]+|\n/g); // Include new lines in the regex
  
    if (!tokens) return html; // Return original HTML if regex match fails
  
    tokens.forEach((token, index) => {
      const isClosingTag = token.startsWith("</");
      const isOpeningTag = token.startsWith("<") && !isClosingTag;
      const isText = !isOpeningTag && !isClosingTag;
      const isNewLine = token === "\n";
  
      // Skip multiple newlines or whitespaces
      if (isNewLine || (!token.trim() && isText)) {
        const nextTokenIsNewLine = tokens[index + 1] === "\n";
        if (!nextTokenIsNewLine) result += "\n" + "  ".repeat(indentLevel);
        return;
      }
  
      const tagName = token.match(/^<\/?(\w+)/)?.[1] ?? "";
  
      if (isClosingTag) {
        if (!inlineTags.includes(tagName)) {
          indentLevel = Math.max(indentLevel - 1, 0);
          result += "\n" + "  ".repeat(indentLevel);
        }
        result += token;
      } else if (isOpeningTag) {
        if (!inlineTags.includes(tagName)) {
          result += "\n" + "  ".repeat(indentLevel);
          indentLevel++;
        }
        result += token;
      } else if (isText) {
        result += token.trimStart(); // Remove leading spaces from text nodes
      }
    });
  
    return result.trim();
  }
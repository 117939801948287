import { useState, useEffect, useCallback } from 'react';
import { BlogModelGet } from '../models/BlogModel'; // Adjust the import path as necessary
import { ApiService } from '../services/ApiService'; // Adjust the import path as necessary

const useBlogDetails = (blogId: string, getAccessTokenSilently: () => Promise<string>) => {
  const [blogDetails, setBlogDetails] = useState<BlogModelGet | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  // Moved fetchDetails outside of useEffect
  const fetchDetails = useCallback(async () => {
    setIsLoading(true);
    try {
      const details: BlogModelGet = await ApiService.fetchBlogDetails(blogId, getAccessTokenSilently);
      setBlogDetails(details);
    } catch (error) {
      console.error("Error fetching blog details:", error);
    } finally {
      setIsLoading(false);
    }
  }, [blogId, getAccessTokenSilently]);

  useEffect(() => {
    if (blogId) {
      fetchDetails();
    }
  }, [blogId, fetchDetails]);

  const refetch = () => {
    fetchDetails();
  };

  return { blogDetails, isLoading, refetch };
};

export default useBlogDetails;

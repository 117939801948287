import StarterKit from "@tiptap/starter-kit";
import Color from "@tiptap/extension-color";
import TextStyle from "@tiptap/extension-text-style";
import Highlight from "@tiptap/extension-highlight";
import Link from "@tiptap/extension-link";
import Table from "@tiptap/extension-table";
import TableRow from "@tiptap/extension-table-row";
import TableHeader from "@tiptap/extension-table-header";
import TableCell from "@tiptap/extension-table-cell";
import Image from "@tiptap/extension-image";
import CharacterCount from "@tiptap/extension-character-count";
import TextAlign from "@tiptap/extension-text-align";
import Underline from "@tiptap/extension-underline";
import FontFamily from "@tiptap/extension-font-family";
import {
  Extension,
  NodeViewRendererProps,
  mergeAttributes,
} from "@tiptap/core";
import Paragraph from "@tiptap/extension-paragraph";
import Gapcursor from "@tiptap/extension-gapcursor";
import { Heading } from "@tiptap/extension-heading";
import { Node } from "prosemirror-model";

const CustomHeading = Heading.extend({
  draggable: true,
  renderHTML({ node, HTMLAttributes }) {
    const level = node.attrs.level;
    return [
      `h${level}`,
      mergeAttributes(HTMLAttributes, { class: "ProseMirror-draggable" }),
      0,
    ];
  },
});
declare module "@tiptap/core" {
  interface Commands<ReturnType> {
    fontSize: {
      setFontSize: (fontSize: string) => ReturnType;
    };
  }
}

export const FontSize = Extension.create({
  name: "fontSize",
  addOptions() {
    return {
      types: ["textStyle"],
    };
  },
  addGlobalAttributes() {
    return [
      {
        types: this.options.types,
        attributes: {
          fontSize: {
            default: null,
            renderHTML: (attributes) => {
              if (!attributes.fontSize) {
                return {};
              }
              return {
                style: `font-size: ${attributes.fontSize}px`,
              };
            },
            parseHTML: (element) => ({
              fontSize: element.style.fontSize.replace(/['"px]+/g, ""),
            }),
          },
        },
      },
    ];
  },
  addCommands() {
    return {
      setFontSize:
        (fontSize) =>
        ({ commands }) => {
          return commands.setMark("textStyle", { fontSize });
        },
    };
  },
});

const CustomParagraph = Paragraph.extend({
  draggable: true,
  renderHTML({ HTMLAttributes }) {
    return [
      "p",
      mergeAttributes(HTMLAttributes, { class: "ProseMirror-draggable" }),
      0,
    ];
  },
});

const CustomImage = Image.extend({
  draggable: true,
  addNodeView() {
    return (props: NodeViewRendererProps) => {
      const { node, getPos, editor } = props;
      const dom = document.createElement("div");
      const img = document.createElement("img");
      const resizeHandle = document.createElement("div");

      // Initial attributes and styles setup
      img.src = node.attrs.src;
      img.style.width = node.attrs.width || "100%";
      img.style.height = node.attrs.height || "auto";
      dom.appendChild(img);

      // Styling for the container and resize handle
      dom.style.display = "inline-block";
      dom.style.position = "relative";
      img.style.position = "relative";
      img.style.zIndex = "1";

      resizeHandle.style.position = "absolute";
      resizeHandle.style.bottom = "0";
      resizeHandle.style.right = "0";
      resizeHandle.style.width = "10px";
      resizeHandle.style.height = "10px";
      resizeHandle.style.background = "#444";
      resizeHandle.style.cursor = "nwse-resize";
      dom.appendChild(resizeHandle);

      // Implement resizing logic
      let startX: number,
        startY: number,
        startWidth: number,
        startHeight: number;

      resizeHandle.addEventListener("mousedown", (e: MouseEvent) => {
        e.preventDefault();
        startX = e.clientX;
        startY = e.clientY;
        startWidth = img.offsetWidth;
        startHeight = img.offsetHeight;

        function onMouseMove(e: MouseEvent) {
          const dx = e.clientX - startX;
          const dy = e.clientY - startY;
          const newWidth = startWidth + dx;
          const newHeight = startHeight + dy;

          img.style.width = `${newWidth}px`;
          img.style.height = `${newHeight}px`;
        }

        function onMouseUp() {
          document.removeEventListener("mousemove", onMouseMove);
          document.removeEventListener("mouseup", onMouseUp);

          if (typeof getPos === "function") {
            const pos = getPos();
            if (pos >= 0) {
              // Ensure pos is valid
              editor.commands.updateAttributes("image", {
                width: img.style.width,
                height: img.style.height,
              });
            }
          }
        }

        document.addEventListener("mousemove", onMouseMove);
        document.addEventListener("mouseup", onMouseUp);
      });

      return {
        dom,
        update(updatedNode: Node) {
          if (updatedNode.type === node.type) {
            img.setAttribute("src", updatedNode.attrs.src);
            img.style.width = updatedNode.attrs.width || "100%";
            img.style.height = updatedNode.attrs.height || "auto";
            return true;
          }
          return false;
        },
        destroy() {
          // Clean up event listeners
        },
      };
    };
  },
});

// Define your extensions
export const extensions = [
  StarterKit,
  CustomParagraph,
  Color,
  Highlight,
  TextStyle,
  FontSize,
  Link,
  CustomHeading,
  CustomImage,
  TextAlign.configure({
    types: ["heading", "paragraph"],
  }),
  Table.configure({ resizable: true }),
  TableRow,
  TableHeader,
  TableCell,
  Gapcursor,
  CharacterCount,
  Underline,
  FontFamily,
];
